
import { User } from '@/models';
import { defineComponent, onMounted, ref } from 'vue';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { HomeRoute, UserEditRoute, UserInviteRoute } from '@/router';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useToast } from 'primevue/usetoast';
import { ApiService, LoaderService } from '@/services';

export default defineComponent({
	components: { DataTable, Column },
	setup() {
		const loadToken = LoaderService.invoke();
		const users = ref<User[]>([]);
		const router = useRouter();
		const toast = useToast();

		onMounted(async () => {
			try {
				users.value = await ApiService.get<User[]>('/users');
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to load users. Try again later.',
					life: 3000
				});

				router.push({ name: HomeRoute });
			} finally {
				LoaderService.clear(loadToken);
			}
		});

		const editRoute = (userId: number): RouteLocationRaw => ({
			name: UserEditRoute,
			params: { userId }
		});

		return { users, editRoute, UserInviteRoute };
	}
});
